import React from 'react';
import './privacy.css'


const Privacy = () => (
    <div>
        <p className='title' align='left'>PRIVACY POLICY</p>
        <p className='item' align='left'>We do not collect and save from your social account in any form or sell any information to advertisers or other operators.</p>
        
        <p className='title' align='left'>Personal Information</p>
        <p className='item' align='left'>When you share information through social accounts may be asked to enter the account and password, but that information is management by the system. App does not save account and password in any form or collect and save any personal information in any form on your social account.</p>
        
        <p className='title' align='left'>Privacy Policy Terms</p>
        <p className='item' align='left'>Use this App mean you agree to the terms and conditions of this Privacy Policy. If you do not agree to this policy, please do not use this App. We reserve the right, in our decision to change, modify, add or remove portions of this policy at any time. Please check this page periodically for any changes. Publish any changes to these terms if you continue to use our App future will mean that you have accepted these adjustments.</p>

        <p className='mail' align='left'>E-Mail : iammoyalab@gmail.com</p>
    </div>
);

export default Privacy;